<template>
  <div>
    <div class="" id="appointments-list">
      <!--------------------- loading --------------------->
      <Loading class="section-dark section-loading"></Loading>
      <!--------------------- section --------------------->
      <section v-if="!isLoading" key="content" class="section-duke section-dark jpadding jpadding-20">
        <div class="jtitle jtitle-t40 jtitle-80 title-w-filters duke-style">
          <h1>My Appointments</h1>

          <!-- <div class="jbtns-container mt-4">
            <button
              @click="filterQuery(0)"
              v-bind:class="{ active: filters[0] }"
              class="jbtn-duke jbtn-outline-silver"
            >
              <i class="fal fa-clock"></i> UPCOMING
            </button>
            <button
              @click="filterQuery(1)"
              v-bind:class="{ active: filters[1] }"
              class="jbtn-duke jbtn-outline-silver"
            >
              <i class="fal fa-check"></i> COMPLETED
            </button>
            <button
              @click="filterQuery(2)"
              v-bind:class="{ active: filters[2] }"
              class="jbtn-duke jbtn-outline-silver"
            >
              <i class="fal fa-times"></i> CANCELLED
            </button>
          </div> -->

          <!--------------------- horizontal --------------------->
          <vue-horizontal class="horizontal mt-4" :displacement="0.5" :button-between="false">
            <template v-slot:btn-prev>
              <svg class="btn-left" viewBox="0 0 24 24">
                <path
                  d="m9.8 12 5 5a1 1 0 1 1-1.4 1.4l-5.7-5.7a1 1 0 0 1 0-1.4l5.7-5.7a1 1 0 0 1 1.4 1.4l-5 5z"
                />
              </svg>
            </template>

            <template v-slot:btn-next>
              <svg class="btn-right" viewBox="0 0 24 24">
                <path
                  d="m14.3 12.1-5-5a1 1 0 0 1 1.4-1.4l5.7 5.7a1 1 0 0 1 0 1.4l-5.7 5.7a1 1 0 0 1-1.4-1.4l5-5z"
                />
              </svg>
            </template>

            <!-- content -->
            <div class="horizontal-item">
              <button
                @click="filterQuery(0)"
                v-bind:class="{ active: filters[0] }"
                class="jbtn-duke jbtn-outline-silver"
              >
                <i class="fal fa-clock"></i> UPCOMING
              </button>
              <button
                @click="filterQuery(1)"
                v-bind:class="{ active: filters[1] }"
                class="jbtn-duke jbtn-outline-silver"
              >
                <i class="fal fa-check"></i> COMPLETED
              </button>
              <button
                @click="filterQuery(2)"
                v-bind:class="{ active: filters[2] }"
                class="jbtn-duke jbtn-outline-silver"
              >
                <i class="fal fa-times"></i> CANCELLED
              </button>
            </div>
          </vue-horizontal>
        </div>

        <!--------------------- table --------------------->
        <div v-if="appointmentsList.length" key="list" class="table-responsive">
          <table class="table wht">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Service Name</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(appointment, index) in appointmentsList" :key="appointment.id">
                <td>{{ $moment($C.getDateFromTimestamp(appointment.date)).format("MMM YYYY") }}</td>
                <td>{{ servicesMap.get(appointment.serviceId).name }}</td>
                <td>{{ $C.STATUS.APPOINTMENT_STATUS_INDEX[appointment.status] }}</td>
                <td>
                  <b-dropdown
                    right
                    variant="link"
                    toggle-class="jbtn jbtn-icon jbtn-icon-grey"
                    class="mx-auto mt-3"
                    no-caret
                  >
                    <template #button-content>
                      <i class="fal fa-cog"></i>
                    </template>
                    <b-dropdown-item
                      :to="{ name: 'AppointmentCardOwner', params: { appointmentId: appointment.id } }"
                      ><i class="fal fa-eye mr-2"></i> View details</b-dropdown-item
                    >
                    <!-- <b-dropdown-divider></b-dropdown-divider> -->
                    <!-- <b-dropdown-item @click="openAppointment(index)"
                      ><i class="fal fa-envelope mr-2"></i> Resend request</b-dropdown-item
                    > -->
                    <b-dropdown-item @click="showChangeDateDialog(index)"
                      ><i class="fal fa-clock mr-2"></i> Change date</b-dropdown-item
                    >
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!--------------------- grid --------------------->
        <!-- <div v-if="appointmentsList.length" key="list" class="grid-2">
          <router-link
            v-for="(appointment, index) in appointmentsList"
            :key="appointment.id"
            :to="{name:'AppointmentCardOwner', params: { appointmentId: appointment.id }}"
            class="jcard-duke"
          >
            <h5>
              {{ servicesMap.get(appointment.serviceId).name }}
            </h5>
            <p class="m-0 mt-3"><small>DATE</small></p>
            <p class="m-0">
              {{
                $moment($C.getDateFromTimestamp(appointment.date)).format("DD-MM-YYYY")
              }}
            </p>
            <p class="m-0 mt-3"><small>STATUS</small></p>
            <span class="badge badge-danger m-0">{{
              $C.STATUS.APPOINTMENT_STATUS_INDEX[appointment.status]
            }}</span>
            <b-dropdown
              right
              variant="link"
              toggle-class="jbtn jbtn-icon jbtn-icon-grey"
              class="mx-auto mt-3"
              no-caret
            >
              <template #button-content>
                <i class="fal fa-cog"></i>
              </template>
              <b-dropdown-item :to="{name:'AppointmentCardOwner', params: { appointmentId: appointment.id }}"
                ><i class="fal fa-eye mr-2"></i>
                View details</b-dropdown-item
              >
              <b-dropdown-item @click="openAppointment(index)"
                ><i class="fal fa-envelope mr-2"></i> Resend
                request</b-dropdown-item
              >
              <b-dropdown-item @click="showChangeDateDialog(index)"
                ><i class="fal fa-clock mr-2"></i> Change date</b-dropdown-item
              >
            </b-dropdown>
          </router-link>
        </div> -->
        <!-- old -->
        <!-- <b-dropdown-item
                @click="cancelAppointment(index)"
                :disabled="appointment.status > 1"
                ><i class="fal fa-times mr-2"></i> Cancel
                appointment</b-dropdown-item
              > -->
        <!--------------------- empty --------------------->
        <div v-else key="empty" class="section-empty">
          <h5 class="mb-3"><i class="fad fa-empty-set fa-2x"></i><br /></h5>
          <p class="p-text-s">This list is empty at the moment.</p>
        </div>
      </section>
    </div>

    <!-- ----- change date modal --------------------------------- -->
    <b-modal
      :hide-header="true"
      :hide-footer="true"
      id="edit-appointment-modal"
      ref="edit-appointment-modal"
      centered
      title="BootstrapVue"
    >
      <div class="jdialog-top">
        <div class="close-container">
          <h3><strong>Change date</strong></h3>
          <span @click="$bvModal.hide('edit-appointment-modal')" class="ml-auto jclose"
            ><i class="fa fa-times "></i
          ></span>
        </div>
        <p>
          <small
            >If your appointment hasn't been confirmed yet, you can change the date freely. After
            confirmation, the date change will have to be reappproved by our team.</small
          >
        </p>
      </div>
      <div class="jdialog-main">
        <div class="jcard-border">
          <small>SERVICE NAME</small>
          <h5>{{ editData.name }}</h5>
        </div>

        <div class="jcard-border mt-3">
          <small>SELECT A DATE</small>
          <Datepicker id="datepicker" v-model="editData.date" lang="en" />
        </div>

        <b-alert v-if="showInputError" show variant="danger"
          ><i class="fad fa-exclamation-circle"></i>
          {{ validationMsg }}
        </b-alert>
      </div>
      <div class="jdialog-bottom with-cancel">
        <button @click="$bvModal.hide('edit-appointment-modal')" class="jbtn jbtn-sm jbtn-red">
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="updateAppointment" class="jbtn jbtn-sm">
          <span v-if="isSaving" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
          <span v-else key="button"><i class="fa fa-save"></i> Change</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueHorizontal from "vue-horizontal";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import toast from "@/assets/js/toast";
import Loading from "@/components/general/Loading";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "MyAppointments",
  components: {
    Datepicker: VueDatepickerUi,
    Loading: Loading,
    VueHorizontal
  },
  data() {
    return {
      isLoading: true,
      isEmpty: false,

      //form error alert
      showInputError: false,
      validationMsg: "",

      //form button
      isSaving: false,
      isDone: false,

      //userId: auth.currentUser.uid,
      appointmentsList: [],
      servicesMap: new Map(),
      watchesList: [],

      appointmentId: "",
      editData: {
        name: "",
        date: new Date()
      },

      asyncFlags: [false, false],
      filters: [false, false, false]
    };
  },
  methods: {
    loadingDone: function(error) {
      this.$root.$emit("loadingDone", error);
      if (!error) this.isLoading = false;
    },
    allAppointments: function() {
      this.isLoading = true;
      const id = auth.currentUser.uid;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .where("userId", "==", id)
        .where("paid", "==", true)
        .onSnapshot(querySnapshot => {
          this.appointmentsList.splice(0);
          console.log("REACHED ALL APPONTMENTS");
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.appointmentsList.push(docdata);
          });
          if (this.asyncFlags[1]) this.loadingDone();
          else this.asyncFlags[0] = true;
        });
      // .catch((error) => {
      //   this.isLoading = false;
      //   this.showError = true;
      //   console.log("Error getting appointments: ", error);
      // });
    },
    allServices: function() {
      this.isLoading = true;
      db.collection(C.COLLECTION.SERVICE_CATALOG)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.servicesMap.set(doc.id, docdata);
          });

          console.log("REACHED ALLSERVICES");

          if (this.asyncFlags[0]) this.loadingDone();
          else this.asyncFlags[1] = true;
        })
        .catch(error => {
          this.loadingDone(error);
          console.log("Error getting services: ", error);
        });
    },
    filterQuery: function(filter) {
      if (this.filters[filter]) {
        console.log("removing filter");
        this.changeActiveFilter();
        return;
      }
      //this.isLoading = true;
      const id = auth.currentUser.uid;
      this.appointmentsList.splice(0);
      var ref = db
        .collection(C.COLLECTION.APPOINTMENTS)
        .where("userId", "==", id)
        .where("paid", "==", true);
      if (filter == 0) {
        ref = ref.where("status", ">=", 0).where("status", "<=", 2);
        this.changeActiveFilter(0);
      }
      if (filter == 1) {
        ref = ref.where("status", "==", filter);
        this.changeActiveFilter(1);
      }
      if (filter == 2) {
        ref = ref.where("status", ">=", 4).where("status", "<=", 5);
        this.changeActiveFilter(2);
      }
      ref
        .where("paid", "==", true)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.appointmentsList.push(docdata);
          });
          this.loadingDone();
        })
        .catch(error => {
          this.loadingDone(error);
          console.log("Error getting appointments: ", error);
        });
    },
    changeActiveFilter(clicked) {
      this.$set(this.filters, this.active, false);
      this.$set(this.filters, clicked, true);
      this.active = clicked;
    },
    openAppointment(index) {
      this.$store.commit("setWatch", this.appointmentsList[index]);
      this.$router.push({
        name: "MyWatch",
        params: { id: this.appointmentsList[index].id }
      });
    },
    showChangeDateDialog(index) {
      this.validationMsg = "";
      // load data
      this.appointmentId = this.appointmentsList[index].id;
      this.editData.date = this.$C.getDateFromTimestamp(this.appointmentsList[index].date);
      this.editData.name = this.servicesMap.get(this.appointmentsList[index].serviceId).name;
      // open dialog
      this.$refs["edit-appointment-modal"].show();
    },
    updateAppointment() {
      this.isSaving = true;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .doc(this.appointmentId)
        .set(
          {
            modifiedOn: serverTimestamp,
            date: this.editData.date,
            status: 0
          },
          { merge: true }
        )
        .then(() => {
          this.$refs["edit-appointment-modal"].hide();
          this.isSaving = false;
          toast.success("Appointment date changed.");
        })
        .catch(error => {
          this.isSaving = false;
          toast.error("Couldn't change appointment date. " + error.message);
        });
    },
    cancelAppointment(index) {
      const appointment = this.appointmentsList[index];
      if (appointment.status == 0) {
        console.log("delete appointment...");
        if (confirm("Do you really want to cancel this appointment?")) {
          var docRef = db.collection(C.COLLECTION.APPOINTMENTS).doc(appointment.id);
          docRef
            .delete()
            .then(() => {
              console.log("Appointment successfully canceled!");
            })
            .catch(error => {
              console.error("Error cancelling appointment: ", error);
            });
        }
      } else {
        console.log("send appointment cancelation request...");
        db.collection(C.COLLECTION.APPOINTMENTS)
          .doc(this.appointmentId)
          .set(
            {
              modifiedOn: serverTimestamp,
              cancelledOn: serverTimestamp,
              status: this.$C.STATUS.APPOINTMENT_STATUS.CANCELLATION_PENDING
            },
            { merge: true }
          )
          .then(() => {
            toast.success("Appointment cancellation requested.");
          })
          .catch(error => {
            toast.error("Error cancelling appointment: " + error.message);
          });
      }
    }
  },
  mounted() {
    this.allAppointments();
    this.allServices();
  }
};
</script>

<style scoped></style>
